import { render, staticRenderFns } from "./OverflowableText.vue?vue&type=template&id=b13f3b9a&"
import script from "./OverflowableText.vue?vue&type=script&lang=js&"
export * from "./OverflowableText.vue?vue&type=script&lang=js&"
import style0 from "./OverflowableText.vue?vue&type=style&index=0&id=b13f3b9a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTooltip});
