<script>
export default {
	name: 'OverflowableText',
	props: {
		maxWidth: {
			type: String,
			default: '10vw'
		}
	}
}
</script>

<template>
	<div class="overflowable-text" :style="{ maxWidth }">
		<slot></slot>
		<q-tooltip>
			<slot></slot>
		</q-tooltip>
	</div>
</template>

<style lang="scss">
.overflowable-text {
	overflow: hidden;
	text-overflow: ellipsis;
}

.q-tooltip {
	font-size: 16px;
}
</style>
