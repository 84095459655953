<script>
/* global moment */
import Layout from '@/components/Layout.vue'
import CommonTable from '@/components/CommonTable.vue'
import { mapState, mapActions } from 'vuex'
import OverflowableText from '@/components/OverflowableText.vue'
import CopyableText from '@/components/CopyableText.vue'
import FormFilter from './FormFilter.vue'
import { format } from '@/lib/plugins/date'

export default {
	name: 'SMS',
	components: {
		CommonTable,
		CopyableText,
		OverflowableText,
		Layout,
		FormFilter
	},
	data() {
		return {
			columns: [
				{
					name: 'id',
					label: 'ID(click to copy)',
					field: 'id',
					align: 'left',
					sortable: true
				},
				{
					name: 'clientId',
					label: 'Client ID',
					field: 'clientId',
					align: 'left'
				},
				{
					name: 'deliveryId',
					label: 'Delivery ID',
					field: 'deliveryId',
					align: 'left'
				},
				{
					name: 'phone',
					label: 'Phone',
					field: 'phone',
					align: 'left'
				},
				{
					name: 'message',
					label: 'Message(click to copy)',
					field: 'message',
					align: 'left'
				},
				{
					name: 'success',
					label: 'Success',
					field: 'success',
					format: success => (success ? 'Yes' : 'No'),
					align: 'left'
				},
				{
					name: 'attempt',
					label: 'Attempt',
					field: 'attempt',
					align: 'left'
				},
				{
					name: 'createdAt',
					label: `Created At (${format(moment(), null, 'UTCZ')})`,
					field: 'createdAt',
					format: this.$dateUtils.unaryFormat,
					align: 'left',
					sortable: true
				},
				{
					name: 'updatedAt',
					label: `Updated At (${format(moment(), null, 'UTCZ')})`,
					field: 'updatedAt',
					format: this.$dateUtils.unaryFormat,
					align: 'left',
					sortable: true
				}
			]
		}
	},
	computed: {
		...mapState('sms', ['totalPage', 'data', 'filter'])
	},
	methods: {
		...mapActions('sms', ['getSMS']),
		async onPageChange(pagination) {
			const { sortBy, descending, page, limit } = pagination
			await this.getSMS({
				sortBy: sortBy || 'createdAt',
				sortDirection: sortBy && !descending ? 'ASC' : 'DESC',
				page,
				limit
			})
		}
	}
}
</script>

<template>
	<layout class-name="sms-page" title="SMS">
		<form-filter />
		<common-table
			row-key="id"
			:data="data"
			:columns="columns"
			:on-page-change="onPageChange"
			:total-page="totalPage"
			:current-page="filter.page"
			:current-sort="{
				sortBy: filter.sortBy,
				descending: filter.sortDirection === 'DESC'
			}"
			binary-state-sort
		>
			<template #message="props">
				<copyable-text :text="props.value">
					<overflowable-text>{{ props.value }}</overflowable-text>
				</copyable-text>
			</template>
			<template #id="props">
				<copyable-text :text="props.value">
					<overflowable-text max-width="18vw">
						{{ props.value }}
					</overflowable-text>
				</copyable-text>
			</template>
		</common-table>
	</layout>
</template>

<style lang="scss">
.sms-page {
	display: flex;
}
</style>
