<script>
import CommonForm from '@/components/CommonForm.vue'
import { mapActions, mapState } from 'vuex'
import _ from 'lodash'

export default {
	name: 'FormFilter',
	components: { CommonForm },
	data() {
		return {
			deliveryId: null,
			clientId: null,
			success: null,
			successOptions: [
				{ label: 'Yes', value: true },
				{ label: 'No', value: false }
			]
		}
	},
	async created() {
		const successOption = _.find(this.successOptions, {
			value: this.filter.success
		})
		this.deliveryId = this.filter.deliveryId
		this.clientId = this.filter.clientId
		this.success = successOption ? successOption.label : null
		await this.getClientList()
	},
	watch: {
		deliveryId(id) {
			if (id === '') {
				this.deliveryId = null
			}
		}
	},
	computed: {
		...mapState('sms', ['clientList', 'filter']),
		clientOptions() {
			return _.map(this.clientList, 'id')
		}
	},
	beforeDestroy() {
		this.resetFilter()
	},
	methods: {
		...mapActions('sms', ['getSMS', 'getClientList', 'resetFilter']),
		submit() {
			const { deliveryId, clientId, success } = this
			return this.getSMS({
				deliveryId,
				clientId,
				success: success && success.value
			})
		},
		async reset() {
			this.resetFilter()
			this.syncLocalWithStore()
			await this.submit()
		},
		syncLocalWithStore() {
			this.deliveryId = this.filter.deliveryId
			this.clientId = this.filter.clientId
			this.success = this.filter.success
		}
	}
}
</script>

<template>
	<common-form :reset="reset" :submit="submit">
		<q-input
			v-model="deliveryId"
			no-error-icon
			dense
			placeholder="Delivery ID"
			:rules="$validator.nullableID('Delivery ID')"
		/>
		<q-select
			v-model="clientId"
			dense
			color="standard"
			:options="clientOptions"
			rounded
			popup-content-class="sms-popup-content"
			label="Client ID"
			class="sms-page-select"
		/>
		<q-select
			v-model="success"
			dense
			color="standard"
			:options="successOptions"
			rounded
			popup-content-class="sms-popup-content"
			label="Success"
			class="sms-page-select"
		/>
	</common-form>
</template>

<style lang="scss">
.sms-popup-content {
	.q-item {
		width: 100%;
		margin: 0;
	}
}

.sms-page-select {
	width: 150px;
}
</style>
