<script>
import { copyToClipboard } from 'quasar'

export default {
	name: 'CopyableText',
	props: {
		text: {
			type: String,
			required: true
		}
	},
	methods: {
		async copy() {
			await copyToClipboard(this.text)
			await this.$q.notify({
				message: 'Text Copied',
				position: 'top'
			})
		}
	}
}
</script>

<template>
	<span @click="copy">
		<slot />
	</span>
</template>

<style lang="scss">
.q-notifications {
	.q-notifications__list {
		top: 10px;
	}

	.q-notification.row,
	.q-notification.row > .row,
	.q-notification.row > .row > .row {
		margin: 0;
	}
}
</style>
